import React from 'react';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Classes from './publications.module.css';
import config from '../../config';
import awsbadgesaa from '../assets/images/AWS-SolArchitect-Professional-2020.png';
import Nav from '../assets/css_modules/Navigation.module.css';
import Navigation from '../components/Navigation';
import FrontendStyles from '../assets/css_modules/publications/Frontend/Frontend.module.css';
import { Link } from 'gatsby';

const Publications = () => {
  return (
    <Layout content="Publications" title="Rany ElHousieny">
      <Sidebar />

      <div>
        <header className={Classes.Publications} id="publcations">
          <nav>
            <div className={Classes.Row}>
              <ul className={Classes.MainNav}>
                <li className="nav-item">
                  <a href="/">Author</a>
                </li>
                <li className="nav-item">
                  <Link to="/microfrontends">Micro-Frontends</Link>
                </li>
                <li className="nav-item">
                  <a
                    target="blank"
                    href="https://youtube.com/playlist?list=PLjzEd-em7iW-zRSOcMYfyssfUrXayHV-M"
                  >
                    AWS Microservices
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    target="blank"
                    href="https://youtube.com/playlist?list=PLjzEd-em7iW-YrVYw8fAGzlFAcPOTE2Mh"
                  >
                    React
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div className={Classes.TextBox}>
            <h1 style={{ color: 'white' }}>Publications</h1>
            {'    '}
            <a
              className={Classes.Links}
              target="blank"
              href="https://www.linkedin.com/in/ranyelhousieny/detail/recent-activity/posts/"
            >
              LinkedIn
            </a>{' '}
            <a
              target="blank"
              href="https://ranyel.medium.com"
              className={Classes.Links}
            >
              Medium
            </a>{' '}
            <a
              className={Classes.Links}
              target="blank"
              href="https://www.youtube.com/channel/UCJ6bFYSkBws-ug6fqJ_9CNg"
            >
              YouTube
            </a>{' '}
            <a
              className={Classes.Links}
              target="blank"
              href="https://github.com/ranyelhousieny"
            >
              Github
            </a>
            <a
              className={Classes.Links}
              target="blank"
              href="https://rany.elhousieny.com"
            >
              Home
            </a>
            <p />
            <div className="social-icons">
              {config.publications.map(social => {
                const { icon, url } = social;
                return (
                  <a key={url} href={url} target="_blank">
                    <i className={`fab ${icon}`} />
                  </a>
                );
              })}
            </div>
          </div>
        </header>
      </div>
    </Layout>
  );
};

export default Publications;
